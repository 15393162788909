import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { apiService } from "../services/apiService";
  
  const initialState = {
    isLoading: false,
    Error: null,
    data: [],
  };
  
  export const fetchUsersMasterAtten = createAsyncThunk(
    "usersMasterAtten/fetchUsersMasterAtten",
    async (userId, { rejectWithValue }) => {
      try {
        const resp = await apiService.get(`/attendance/users-master-attendances/${userId}`);
        return resp.data;
      } catch (err) {
        return rejectWithValue({ error: err.message });
      }
    }
  );
  
  export const usersMasterAttnSlice = createSlice({
    name: "usersMasterAtten",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUsersMasterAtten.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchUsersMasterAtten.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.Error = null;
          state.data = payload.apiData;
        })
        .addCase(fetchUsersMasterAtten.rejected, (state, action) => {
          state.Error = action.error.message;
        });
    },
  });
  
  export default usersMasterAttnSlice.reducer;
  