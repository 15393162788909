import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterAtten } from "../../features/masterAttnSlice";
import { useNavigate } from "react-router";

const columns = [
  { field: "attenId", headerName: "AID", width: 150 },
  { field: "firstName", headerName: "First Name", width: 150 },
  { field: "lastName", headerName: "Last Name", width: 150 },
  { field: "date", headerName: "Date", width: 150 },
  { field: "checkIn", headerName: "check In", width: 150 },
  { field: "checkOut", headerName: "check Out", width: 150 },
  { field: "brakeHrs", headerName: "Brake Hrs", width: 150 },
  { field: "meetingHrs", headerName: "Meeting Hrs", width: 150 },
  { field: "totalHrs", headerName: "Total hrs", width: 150 },
  { field: "productivityHrs", headerName: "Prod Hrs", width: 150 },
  { field: "lackHrs", headerName: "Lack Hrs", width: 150 },
  { field: "rating", headerName: "Rating", width: 150 },
//   {
//     field: "isProdLessThanEight",
//     headerName: "Prod Less than 8hrs",
//     width: 150,
//   },
//   { field: "isCheckIn", headerName: "Is Checked In", width: 150 },
//   { field: "isCheckOut", headerName: "Is Checked Out", width: 150 },
  { field: "remarks", headerName: "Remarks", width: 150 },
];

const MasterAtten = (props) => {
  const masterAtten = useSelector((state) => state.masterAtten);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMasterAtten());
  }, [dispatch]);

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        field: "date",
        operator: "contains",
        value: moment().format("YYYY-MM-DD"),
      },
    ],
  });

  const handleRowClick =  async (params) =>{
    const rowId = params.row.attenId;

    navigate(`/app/attendanceMaster/${rowId}`)

  }

  return (
    <div className="bg-slate-50 shadow-md">
      <div className="h-96">
        <DataGrid
          getRowId={(row) => row.attenId}
          rows={masterAtten.data}
          
          columns={columns}
          loading={masterAtten.isLoading}
          error={true}
          errorMessage={masterAtten.error}
          
          errorOverlayStyle={{
            backgroundColor: "red",
            color: "white",
          }}
          onRowClick={handleRowClick}
          // filterModel={filterModel}
          // onFilterModelChange={(model) => setFilterModel(model)}
        />
      </div>
    </div>
  );
};

export default MasterAtten;
