import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router";
import { apiService } from "../../../../services/apiService";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserMenus } from "../../../../features/userMenuSlice";
import { CircularProgress, Skeleton } from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';

const SideNavMenuItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userMenus = useSelector((state) => state.userMenus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserMenus());
  },[dispatch]);

  return (
    <div className=" text-slate-300  ">
      <div className="flex flex-col justify-center mt-14">
        {userMenus.isLoading ? (
          <>
            <Skeleton animation="wave" variant="rectangular" height={400} />
          </>
        ) : (
          <>
            {userMenus.data.map((menu) => (
              <ListItemButton
              key={menu.id}
                onClick={() => navigate(menu.navigation)}
                selected={location.pathname === menu.navigation ? true : false}
              >
                <ListItemIcon sx={{ color: "#f1f5f9" }}>
                  {getIcon(menu.icon)}
                </ListItemIcon>
                <ListItemText primary={menu.menuLabel} />
              </ListItemButton>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

function getIcon(type) {
  switch (type) {
    case "DashboardIcon":
      return <DashboardIcon />;
    case "PeopleIcon":
      return <PeopleIcon />;
    case "BarChartIcon":
      return <BarChartIcon />;
    case "LayersIcon":
      return <LayersIcon />;
    case "AssessmentIcon":
      return <AssessmentIcon />;
    default:
      return null;
  }
}

export default SideNavMenuItems;
