import { Avatar, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyDirectsStatus } from "../../features/myDirectsStatusSlice";

const MyDirectStatus = () => {
  const myDirectsStatus = useSelector((state) => state.myDirectsStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMyDirectsStatus());
  }, [dispatch]);
  return (
    <div className=" h-96 rounded-md p-4 overflow-y-auto bg-slate-50 shadow-md ">
      {/* MAP FUNCTION NEED HERE */}
      {myDirectsStatus.isLoading ? (
        <>
          <div className="flex flex-col items-center justify-center h-full ">
            <CircularProgress />
            <p className="text-xs text-red-700">{myDirectsStatus.Error}</p>
          </div>
        </>
      ) : (
        <>
          {myDirectsStatus.data.map((s) => (
            
              <div className="rounded-xl bg-gray-100 text-sm  text-gray-900 shadow-sm" key={s.userId}>
                <div className="flex space-x-4 p-2 mb-2">
                  <div
                    className={`border-2 border-solid ${
                      s.status == "Not Checked In"
                        ? "border-red-600"
                        : "border-green-600"
                    }`}
                  ></div>
                  <div className="mb-2" key={s.userId}>
                    <h1 className="font-semibold" >
                      {s.firstName} {""}
                      {s.lastName}
                    </h1>
                    <p className="text-xs">Status: {s.status}</p>
                  </div>
                </div>
              </div>
      
          ))}
        </>
      )}
    </div>
  );
};

export default MyDirectStatus;
