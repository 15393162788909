import React from "react";
import SideNavMenuItems from "./SideNavMenuItems";
import wlogo from "../../../../assets/timekeeper-logo.png";
import kologow from "../../../../assets/kologow.png";
const SideNav = () => {
  return (
    <div className="w-72 h-screen bg-blue-950 ">
      <div className=" flex flex-col justify-start mt-6">
        <img src={wlogo} className="p-4 pb-0 items-center" />
        <p className="text-center text-xs text-gray-500">v1 beta release</p>
      </div>

      <SideNavMenuItems />

      <div className="h-80 flex flex-col justify-end">
        <div className="flex items-center justify-center">
          <img src={kologow} className="w-40 h-18 mb-2 opacity-50" />
        </div>
        <p className="text-center text-gray-400 text-xs ">
          Powered By KOSOFT IT SOLUTIONS
        </p>
      </div>
    </div>
  );
};

export default SideNav;
