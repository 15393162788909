import React from 'react'

const MyDirectsStats = () => {
  return (
    <div className='mt-4 mb-4'>
        {/* <div className='grid grid-cols-3 gap-4 h-32 text-stone-50'>
        <div className='bg-purple-700 rounded-md shadow-sm  flex justify-between'>
            <p className='text-2xl font-thin'>Directs</p>
            <p className='text-4xl font-thin'>9</p>
        </div>
        <div className='bg-indigo-700 rounded-md shadow-sm'>on</div>
        <div className='bg-violet-700 rounded-md shadow-sm'>on</div>
        </div> */}
        <div className='h-32  items-center flex flex-col bg-slate-200'>
            <p className='text-sm mt-14 text-gray-400'> Directs Stats Coming soon</p>
        </div>
    </div>
  )
}

export default MyDirectsStats