import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../services/apiService";


const initialState = {
  isProgressLoading: false,
  MeetingData: [],
  error: null,
};

export const fetchAttnMeetings = createAsyncThunk(
  "attnMeetings/fetchAttnMeetings",
  async (attnId) => {
    try {

      const response = await apiService.get(
        `/UserReports/userMeetings/${attnId}`
      );

      return response.data;
    } catch (err) {
        console.log("ERROR", err)
      // return rejectWithValue(err.response.data);
    //   return rejectWithValue({ error: err.message });
    }
  }
);

export const attnMeetingsSlice = createSlice({
  name: "attnMeetings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttnMeetings.pending, (state, action) => {
        state.isProgressLoading = true;
        state.error = null;
      })
      .addCase(fetchAttnMeetings.fulfilled, (state, { payload }) => {
        state.isProgressLoading = false;
        state.MeetingData = payload.apiData;
      })
      .addCase(fetchAttnMeetings.rejected, (state, action) => {
        state.isProgressLoading = false;
        // (state.brakeData = null), (state.error = action.error.message);
      });
  },
});

export default attnMeetingsSlice.reducer;
