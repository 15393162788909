import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Stack,
  TextField,
  FormControlLabel,
  FormGroup,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";

import { apiService } from "../../services/apiService";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchAppUsers } from "../../features/appUsersSlice";

const AddUser = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newUsers, setNewUsers] = useState([]);
  const [openDiag, setOpenDiag] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [empId, setEmpId] = useState("");
  const [isAttnExp, setIsAttnExp] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedRepOption, setSelectedRepOption] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const dispatch = useDispatch()

  const selectedOptionObject = newUsers.find(
    (newUser) => newUser.azureId === selectedOption
  );

  const selectedRepOptionObject = allUsers.find(
    (allUser) => allUser.userId === selectedRepOption
  );

  const GetNewUser = async () => {
    try {
      setIsLoading(true);
      setOpenDiag(true);
      const resp = await apiService.get("/accounts/new-users");
      console.log(resp);
      if (resp.data.sucess) {
        const repUsers = await apiService.get("/accounts/users");
        if (repUsers.data.sucess) {
          setNewUsers(resp.data.apiData);
          setAllUsers(repUsers.data.apiData);
          setIsLoading(false);
        }

        console.log("RESP", resp.data.apiData);
      }
    } catch (err) {
      console.log("ERROE", err);
    }
  };

  const handleaddUser = async (event) => {
    event.preventDefault();
    // const newUserData = {
    //   azureId: selectedOptionObject.azureId,
    //   firstName: selectedOptionObject.firstName,
    //   lastName: selectedOptionObject.lastName,
    //   // managerId: selectedRepOptionObject.userId,
    //   empId: empId,
    //   email: selectedOptionObject.email,
    //   isActive: isActive,
    //   isAttenExecption: isAttnExp,
    // };
    // console.log("New user", newUserData);
    try {
      setIsLoading(true)
      const newUser = await apiService.post("/accounts/add-newuser", {
        azureId: selectedOptionObject.azureId,
        firstName: selectedOptionObject.firstName,
        lastName: selectedOptionObject.lastName,
        managerId: selectedRepOptionObject.userId,
        empId: empId,
        email: selectedOptionObject.email,
        isActive: isActive,
        isAttenExecption: isAttnExp,
      });
      if (newUser.data.sucess) {
        props.GetUsers();
        setSelectedOption("");
        setSelectedRepOption("");
        setIsLoading(false)
        setOpenDiag(false);
        dispatch(fetchAppUsers())
      }
    } catch (err) {
      console.log("data", selectedOptionObject);
      console.log("APPER", selectedOption);
    }
  };

  const handleCancel = () => {
    setSelectedOption("");
    setSelectedRepOption("");

    setOpenDiag(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={GetNewUser}>
        Add User
      </Button>
      <div>
        <Dialog open={openDiag}>
          {isLoading ? (
            <div className="w-96 h-32 text-center mt-20">
              <CircularProgress />
            </div>
          ) : (
            <>
              <DialogContent className="w-96">
                <div>
                  <div>
                    <p className="text-2xl text-gray-800">OnBoard New User</p>
                  </div>
                  <div className="mt-4">
                    <form onSubmit={handleaddUser}>
                      <FormGroup>
                        <Stack spacing={2}>
                          <FormControl required={true}>
                            <InputLabel>User</InputLabel>

                            <Select
                              label="User"
                              value={selectedOption}
                              defaultValue=""
                              onChange={(e) =>
                                setSelectedOption(e.target.value)
                              }
                              required={true}
                            >
                              <MenuItem value="" disabled>
                                Select an option
                              </MenuItem>
                              {newUsers.map((u) => (
                                <MenuItem
                                  className="p-4"
                                  key={u.azureId}
                                  value={u.azureId}
                                >
                                  {u.firstName} {""} {u.lastName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl required={true}>
                            <TextField
                              required={true}
                              label="Employee Id"
                              onChange={(e) => setEmpId(e.target.value)}
                            />
                          </FormControl>
                          <FormControl required={true}>
                            <InputLabel>Reporting To</InputLabel>
                            <Select
                              label="Reporting To"
                              defaultValue=""
                              value={selectedRepOption}
                              required={true}
                              onChange={(e) =>
                                setSelectedRepOption(e.target.value)
                              }
                            >
                              {allUsers.map((r) => (
                                <MenuItem key={r.userId} value={r.userId}>
                                  {r.firstName}
                                  {""} {r.lastName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <FormControlLabel
                            control={
                              <Checkbox onChange={() => setIsAttnExp(true)} />
                            }
                            label="Is Attendance Expection"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox onChange={() => setIsActive(true)} />
                            }
                            label="Is Active"
                          />
                        </Stack>
                      </FormGroup>
                      <div className="flex mt-4 justify-end space-x-4">
                        <Button variant="outlined" onClick={handleCancel}>
                          Cancel
                        </Button>
                        <Button variant="outlined" type="submit">
                          Add User
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </DialogContent>
            </>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default AddUser;
