import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersMasterAtten } from "../../features/usersMasterAttnSlice";
import CheckIcon from "@mui/icons-material/Check";
import { Button } from "@mui/material";
import { apiService } from "../../services/apiService";
import { fetchGetUser } from "../../features/getUserSlice";
import CircularProgress from '@mui/material/CircularProgress';

const columns = [
  { field: "date", headerName: "Date", width: 150 },
  { field: "checkIn", headerName: "check In", width: 150 },
  { field: "checkOut", headerName: "check Out", width: 150 },

  { field: "meetingHrs", headerName: "Meeting Hrs", width: 150 },
  { field: "totalHrs", headerName: "Total hrs", width: 150 },
  { field: "productivityHrs", headerName: "Prod Hrs", width: 120 },
  { field: "lackHrs", headerName: "Lack Hrs", width: 120 },
  { field: "rating", headerName: "Rating", width: 120 },
];

const UsersMasterAttn = () => {
  const { userId } = useParams();
  const usersMasterAtten = useSelector((state) => state.usersMasterAtten);
  const getUser = useSelector((state) => state.getUser);
  const [disloading, setDisloading] = useState(false);
  const navigate = useNavigate()

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGetUser(userId));
    dispatch(fetchUsersMasterAtten(userId));
  }, [dispatch, userId]);

  const renderCell = (params) => {
    const value = params.value;
    if (value === null || value === undefined) {
      return <span>-</span>; // Render a dash or any other placeholder for null values
    }
    return value;
  };

  const deactiveUser = async () => {
    setDisloading(true)
    try {
      const resp = await apiService.post("/accounts/deactivate-user", {
        AppGuid: userId,
      });
      if(resp.data.sucess){
        setDisloading(false)
        window.location.reload();
        
      }
    } catch (err) {}
  };
  const handleRowClick =  async (params) =>{
    const rowId = params.row.attenId;

    navigate(`/app/attendanceMaster/${rowId}`)

  }
  return (
    <div>
      {usersMasterAtten.data && usersMasterAtten.data.length > 0 ? (
        <div>
          <div className="">
            <h1 className="text-xl text-stone-900 mb-4 font-semibold">
              Name: {getUser.data.firstName} {getUser.data.lastName}
            </h1>
            <p>Employee ID: {getUser.data.empId}</p>
            <p>Email: {getUser.data.email}</p>
          </div>
          {getUser.data.isActive ? (
            <></>
          ) : (
            <>
              <h1 className="text-red-600 text-2xl ">User Deactivated</h1>
            </>
          )}

          <div className="mt-2 mb-2 flex justify-between">
            <h1></h1>
            {getUser.data.isActive ? (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={deactiveUser}
                  disabled={!getUser.data.isActive}
                  endIcon={disloading && <CircularProgress size={20} sx={{color:"white"}}/>}
                >
                  Deactivate
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="error"
                  disabled={!getUser.data.isActive}
                >
                  deactivate
                </Button>
              </>
            )}
          </div>
          <div className="w-full h-96 bg-slate-50 shadow-md">
            <DataGrid
              renderCell={renderCell}
              getRowId={(row) => row.attenId}
              rows={usersMasterAtten.data}
              columns={columns}
              loading={usersMasterAtten.isLoading}
              error={true}
              errorMessage={usersMasterAtten.error}
              errorOverlayStyle={{
                backgroundColor: "red",
                color: "white",
              }}
              onRowClick={handleRowClick}
              // filterModel={filterModel}
              // onFilterModelChange={(model) => setFilterModel(model)}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-32 text-center mt-44">
          <h1 className="text-2xl text-slate-700">
            No Information for this user
          </h1>
        </div>
      )}
    </div>
  );
};

export default UsersMasterAttn;
