import { CircularProgress } from "@mui/material";
import React from "react";

const UserAttenBrakes = ({ attnBrakes }) => {
  return (
    <div>
      <h1 className="text-slate-600 mb-2">Brakes</h1>
      {attnBrakes.isProgressLoading ? (
        <>
          <div className=" h-96 rounded-md p-4 overflow-y-auto bg-slate-50 shadow-md w-96">
            <div className="flex flex-col items-center justify-center h-full ">
              <CircularProgress />
              <p className="text-xs text-red-700">{attnBrakes.Error}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className=" h-96 rounded-md p-4 overflow-y-auto bg-slate-50 shadow-md w-96">
            {attnBrakes.brakeData.length === 0 ? (
              <>
                <div className="flex flex-col items-center justify-center h-full ">
                  <h1>No Data</h1>
                </div>
              </>
            ) : (
              <>
                {attnBrakes.brakeData.map((b) => (
                  <div
                    className="rounded-xl bg-gray-100 text-sm mb-2 text-gray-900 shadow-sm"
                    key={b.brakeId}
                  >
                    <div className="p-2 text-xs">
                      <h1>Brake Start: {b.brakeStart}</h1>
                      <h1>Brake End: {b.brakeEnd}</h1>
                      <p>Reason:</p>
                      <div className="h-12  mt-1  rounded-md overflow-y-auto">
                        <p>{b.reason}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserAttenBrakes;
