import React, {useEffect} from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../configs/authConfigs";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { InteractionType } from "@azure/msal-browser";


const SignIn = () => {
  const { instance, accounts, inProgress  } = useMsal();
  const navigate = useNavigate();



    const handleRedirect = () => {
        instance
            .loginRedirect({
                ...loginRequest
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {

        if (accounts.length > 0) {
            navigate("/app")}

    }, [accounts]);

    if (accounts.length > 0) {
        return <span>There are currently {accounts.length} users signed in!</span>
    } else if (inProgress === "login") {
        return <span>Login is currently in progress!</span>
    } else {
        return (
            <>

                <Button onClick={() => instance.loginRedirect({...loginRequest} )}>Login</Button>
            </>
        );
    }


};

export default SignIn;
