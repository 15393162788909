import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyDirectsAttendance from "./MyDirectsAttendance";
import { fetchDirectAtten } from "../../features/directsAttnSlice";
import MyDirectStatus from "./MyDirectStatus";
import MyDirectsStats from "./MyDirectsStats";


const MyDirectsAttenLayout = () => {
  const directsAtten = useSelector((state) => state.directsAttn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDirectAtten());
  }, [dispatch]);
  return (
    <div>
      <div>
        <MyDirectsStats />
      </div>
      <p className="text-xl text-gray-600 mt-2 mb-2">
        Directs Attendance Report
      </p>
      {/* <p className="text-sm text-stone-500 mb-1">Default selected Date: {moment().format("YYYY-MM-DD")}</p> */}
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2 ">
          <MyDirectsAttendance directsAtten={directsAtten} />
        </div>

        <MyDirectStatus />
      </div>
    </div>
  );
};

export default MyDirectsAttenLayout;
