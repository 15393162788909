
import { LogLevel } from "@azure/msal-browser";
import { AZURE_API_SCOPE, AZURE_CLIENT_ID, AZURE_REDIRECT_URI, AZURE_TENANT_ID } from "../constants/azureConstants";

export const msalconfig ={

    auth:{
        clientId: AZURE_CLIENT_ID,
        authority: AZURE_TENANT_ID,
        redirectUri:AZURE_REDIRECT_URI,
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // system: {
    //     loggerOptions: {
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return;
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message);
    //                     return;
    //                 case LogLevel.Info:
    //                     console.info(message);
    //                     return;
    //                 case LogLevel.Verbose:
    //                     console.debug(message);
    //                     return;
    //                 case LogLevel.Warning:
    //                     console.warn(message);
    //                     return;
    //                 default:
    //                     return;
    //             }
    //         }
    //     }
    // }
    
}

console.log(AZURE_REDIRECT_URI)
export const loginRequest = {
    scopes: AZURE_API_SCOPE
};

// export const graphConfig = {
//     graphMeEndpoint: "Enter_the_Graph_Endpoint_Herev1.0/me" //e.g. https://graph.microsoft.com/v1.0/me
// };