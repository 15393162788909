import React from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";

import {Box, Typography} from "@mui/material";


const columns = [
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "empId", headerName: "Emp ID", width: 150 },


];
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{
                fileName: `timeKeeper-notcheckin`,
                delimiter: ';',
                utf8WithBom: true,

            }}/>
            <Box className="text-gray-700" style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="h6">NOT CHECK IN REPORT</Typography>
            </Box>
        </GridToolbarContainer>
    );
}
function NotCheckInReport(props) {




    return (
        <div className="h-80">
            <DataGrid
            loading={props.notCheckInLoading}
            getRowId={(row) => row.userId}
            columns={columns}
            rows={props.notCheckInRp}
            slots={{
            toolbar: CustomToolbar,
        }}

            />
        </div>
    );
}

export default NotCheckInReport;