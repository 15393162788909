import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { apiService } from "../services/apiService";

const initialState = {
  isLoading: false,
  Error: null,
  data: [],
};

export const fetchUserMenus = createAsyncThunk(
  "userMenus/fetchUserMenus",
  async (arg, { rejectWithValue }) => {
    try {
      const resp = await apiService.get("/app/usermenus");
      return resp.data;
    } catch (err) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const userMenuSlice = createSlice({
  name: "userMenus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMenus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUserMenus.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.Error = null;
        state.data = payload.apiData;
      })
      .addCase(fetchUserMenus.rejected, (state, action) => {
        state.Error = action.error.message;
      });
  },
});

export default userMenuSlice.reducer;
