import React from "react";
import MasterAttenStats from "./MasterAttenStats";
import MasterAtten from "./MasterAtten";
import MasterAttenStatus from "./MasterAttenStatus";

const MasterAttenLayout = () => {
  return (
    <div>
      <div>
        <MasterAttenStats />
      </div>
      <p className="mt-2 text-xl text-gray-700">Master Attendance</p>
      <div className="grid grid-cols-3 gap-4 mt-2">
        <div className="col-span-2 ">
          <MasterAtten />
        </div>
        <MasterAttenStatus />
      </div>
    </div>
  );
};

export default MasterAttenLayout;
