import { AppBar, Avatar, Box, Button, Container, IconButton } from "@mui/material";
import React from "react";
import { useMsal } from "@azure/msal-react";

const TopNavItems = () => {

  const { instance } = useMsal();

  const handleSignOut = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };
  return (
    <div className="flex  justify-end p-6">
      
      <Button variant="outlined" onClick={handleSignOut}>log Out</Button>
    </div>
  );
};

export default TopNavItems;
