import React, {useEffect} from "react";
import {Routes, Route, useNavigate} from "react-router";
import DashBoard from "../components/Dashboard/DashBoard";
import SideNav from "../components/common/Nav/SideNav/SideNav";
import TopNav from "../components/common/Nav/TopNav/TopNav";
import UserManagement from "../components/UserManagement/UserManagement";
import MyDirectsAttenLayout from "../components/Attendance/MyDirectsAttenLayout";
import MasterAttenLayout from "../components/Attendance/MasterAttenLayout";
import UsersMasterAttn from "../components/Attendance/UsersMasterAttn";
import UserAttenLayout from "../components/Attendance/UserAttendance/UserAttenLayout";
import DailyReport from "../components/Reports/DailyReports/DailyReport";
import {AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal} from '@azure/msal-react';
import {loginRequest} from "../configs/authConfigs";
import { InteractionType } from "@azure/msal-browser";
import DailyReportsLayout from "../components/Reports/DailyReports/DailyReportsLayout";
const PrivateLayout = () => {
  const { instance, accounts, inProgress  } = useMsal();

  return (

            <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>


                <div className="bg-violet-50">
                  <div className="flex h-screen">
                    <div className="flex-1 flex flex-col md:flex-row">
                      <SideNav/>
                      <div className="flex-1 overflow-y-auto h-screen">
                        <TopNav/>
                        <div className="p-4 flex-1 ">
                          <Routes>
                            <Route path="/" element={<DashBoard/>}/>
                            <Route path="/users" element={<UserManagement/>}/>
                            <Route path="/users/:userId" element={<UsersMasterAttn/>}/>
                            <Route path="/dir-sub-atten" element={<MyDirectsAttenLayout/>}/>
                            <Route path="/daily-report" element={<DailyReportsLayout/>}/>
                            <Route path="/attendanceMaster" element={<MasterAttenLayout/>}/>
                            <Route path="/attendanceMaster/:attenId" element={<UserAttenLayout/>}/>
                          </Routes>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </MsalAuthenticationTemplate>

  );
};

export default PrivateLayout;
