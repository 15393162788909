import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterStatus } from "../../features/masterStatusSlice";

const MasterAttenStatus = () => {
  const masterStatus = useSelector((state) => state.masterStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMasterStatus());
  }, []);

  return (
    <div className=" h-96 rounded-md p-4 overflow-y-auto bg-slate-50 shadow-md ">
      {/* MAP FUNCTION NEED HERE */}
      {masterStatus.data.map((s) => (
        <div className="rounded-xl bg-gray-100 text-sm  text-gray-900 shadow-sm"  key={s.userId}>
          <div className="flex space-x-4 p-2 mb-2">
            <div
              className={`border-2 border-solid ${
                s.status == "Not Checked In"
                  ? "border-red-600"
                  : "border-green-600"
              }`}
            ></div>
            <div className="mb-2" key={s.userId}>
              <h1 className="font-semibold">
                {s.firstName} {""} {s.lastName}
              </h1>
              <p className="text-xs">Status: {s.status}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MasterAttenStatus;
