import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import {EventType, PublicClientApplication} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalconfig } from './configs/authConfigs';
import moment from 'moment';
import 'moment-timezone'

const container = document.getElementById("root");
const root = createRoot(container);
export const msalInstance = new PublicClientApplication(msalconfig);
moment.tz.setDefault('Asia/Kolkata');




root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
          <MsalProvider instance={msalInstance}>
              <App />
          </MsalProvider>

      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
