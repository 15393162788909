export const AZURE_TENANT_ID = "https://login.microsoftonline.com/df2b60de-e9ac-4e5b-b6c4-48c497b72c88"
export const AZURE_REDIRECT_URI = "https://timekeeper.corp.kosoftitsolutions.com"

export const AZURE_API_SCOPE = ["api://52eeba09-624b-4e55-8a5a-7fb6683f278c/User.Read"]
export const AZURE_CLIENT_ID = "52eeba09-624b-4e55-8a5a-7fb6683f278c"



// export const AZURE_TENANT_ID = window._env_.REACT_APP_AZURE_TENANT_ID
// export const AZURE_REDIRECT_URI = window._env_.REACT_APP_AZURE_REDIRECT_URI
// export const AZURE_API_SCOPE = window._env_.REACT_APP_AZURE_API_SCOPE
// export const AZURE_CLIENT_ID = window._env_.REACT_APP_AZURE_CLIENT_ID