import { configureStore } from '@reduxjs/toolkit';
import userMenuSlice from '../features/userMenuSlice';
import thunkMiddleware from "redux-thunk";
import directsAttnSlice from '../features/directsAttnSlice';
import appUsersSlice from '../features/appUsersSlice';
import masterAttnSlice from '../features/masterAttnSlice';
import myDirectsStatusSlice from '../features/myDirectsStatusSlice';
import masterStatusSlice from '../features/masterStatusSlice';
import usersMasterAttnSlice from '../features/usersMasterAttnSlice';
import attnBrakesSlice from '../features/attnBrakesSlice';
import attnMeetingsSlice from '../features/attnMeetingsSlice';
import getUserSlice from '../features/getUserSlice';

export const store = configureStore({
  reducer: {
    userMenus: userMenuSlice,
    directsAttn: directsAttnSlice,
    appUsers: appUsersSlice,
    masterAtten: masterAttnSlice,
    myDirectsStatus: myDirectsStatusSlice,
    masterStatus: masterStatusSlice,
    usersMasterAtten: usersMasterAttnSlice,
    attnBrakes: attnBrakesSlice,
    attnMeetings: attnMeetingsSlice,
    getUser: getUserSlice,
  },
  middleware: [thunkMiddleware],
});
