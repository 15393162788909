import React from 'react';

import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {Box, Typography} from "@mui/material";

const columns = [
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "empId", headerName: "Emp ID", width: 150 },
    { field: "checkInTime", headerName: "Check In Time", width: 150 },


];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{
                fileName: `timeKeeper-not-checkout`,
                delimiter: ';',
                utf8WithBom: true,
            }}/>
            <Box className="text-gray-700" style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="h6"> NOT CHECK OUT REPORT</Typography>
            </Box>
        </GridToolbarContainer>
    );
}
function NotCheckOutReport(props) {
    return (
        <div>
            <div className="h-80 " >
                {/*<h1 className="text-blue-900 text-xl font-semibold justify-center items-center flex">*/}
                {/*    NOT CHECK OUT REPORT*/}
                {/*</h1>*/}
                <DataGrid
                    loading={props.notCheckOutRpLoading}
                    getRowId={(row) => row.attnId}
                    columns={columns}
                    rows={props.notCheckOutRp}
                    slots={{
                        toolbar: CustomToolbar,
                    }}


                />
            </div>

        </div>
    );
}

export default NotCheckOutReport;