import React from "react";
import TopNavItems from "./TopNavItems";

const TopNav = () => {
  

  return (
    <div className="w-full bg-slate-100 h-20 shadow-sm">
      <TopNavItems />
    </div>
  );
};

export default TopNav;
