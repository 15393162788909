import axios from "axios";
import { CORE_API_URL } from "../constants/apiConstants";
import { msalInstance } from "..";
import { AZURE_API_SCOPE } from "../constants/azureConstants";

export const apiService = axios.create({
  baseURL: CORE_API_URL,
});

const getToken = async () => {
  try {
    const accounts = await msalInstance.getAllAccounts();
    if (accounts.length === 0) throw new Error("No Accounts found");

    const silentRequest = {
      scopes: AZURE_API_SCOPE,
      account: accounts[0],
    };

    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (err) {
    console.log("AC TOKEN ERR")
  }
};


apiService.interceptors.request.use(
  async function (config) {
    const token = await getToken()
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),

);
