import React, {useEffect} from "react";
import {Route, Routes, useNavigate} from "react-router";
import PrivateLayout from "../layouts/PrivateLayout";
import AppError from "../components/common/AppError";
import {AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal,} from '@azure/msal-react';
import {loginRequest} from "../configs/authConfigs";
import {Button} from "@mui/material";
import PublicLayout from "../layouts/PublicLayout";



const PrivateRoutes = () => {
    const { instance, accounts, inProgress  } = useMsal();

    return (
        <div>

                <Routes>
                    <Route path="/*" element={<PrivateLayout/>}/>
                    <Route path="app-error" element={<AppError/>}/>
                </Routes>


        </div>
    );
};

export default PrivateRoutes;
