import React, { useState, useEffect } from "react";
import UserAttenInfo from "./UserAttenInfo";
import UserAttenBrakes from "./UserAttenBrakes";
import UserAttenMeetings from "./UserAttenMeetings";
import { useParams } from "react-router";
import { Button, CircularProgress } from "@mui/material";
import { apiService } from "../../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { fetchAttnBrakes } from "../../../features/attnBrakesSlice";
import { fetchAttnMeetings } from "../../../features/attnMeetingsSlice";
import moment from "moment";
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";

const UserAttenLayout = () => {
  const { attenId } = useParams();

  const [userInfo, setUserInfo] = useState({});
  const [err, setErr] = useState("loading...");
  const [isLoading, setIsloading] = useState(true);
  const [isButtonDisabled, setIsButtonHisabled] = useState(true);
  const [checkOutLoading, setCheckOutLoading] = useState(false);

  const dispatch = useDispatch();
  const attnMeetings = useSelector((state) => state.attnMeetings);
  const attnBrakes = useSelector((state) => state.attnBrakes);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUserAttenInfo();
    dispatch(fetchAttnBrakes(attenId));
    dispatch(fetchAttnMeetings(attenId));
  }, [dispatch, attenId]);

  const getUserAttenInfo = async () => {
    try {
      const resp = await apiService.get(
        `/attendance/userAttendance/${attenId}`
      );
      if (resp.data.sucess) {
        setUserInfo(resp.data.apiData);
        const date = moment().format("YYYY-MM-DD");
        if (date === resp.data.apiData.date) {
          setIsButtonHisabled(false);
        }
        if(resp.data.apiData.checkOut === "00:00:00"){
          setIsButtonHisabled(true)
        }

        // resp.data.apiData.date = moment().format("YYYY-MM-DD") ? setIsButtonHisabled(false): setIsButtonHisabled(true)

        setIsloading(false);
      }
    } catch (error) {
      setErr(error);
    }
  };

  const handleClearCheckOut = async () =>{
    try{
      setCheckOutLoading(true)
      const resp = await apiService.post(`/Attendance/removeCheckout/${attenId}`)
      if (resp.data.sucess){
        setCheckOutLoading(false)
        setOpen(false)
      }
      // setCheckOutLoading(false)
    }
    catch(error){

    }
  }

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-full mt-44">
          <CircularProgress />
          <p className="text-xs text-red-700">{err}</p>
        </div>
      ) : (
        <>
          <div>
            <UserAttenInfo userInfo={userInfo} />
            <div className="flex justify-between mt-4">
              <UserAttenBrakes attnBrakes={attnBrakes} />
              <UserAttenMeetings attnMeetings={attnMeetings} />
              <Button
                variant="outlined"
                className="w-96"
                color="error"
                disabled={isButtonDisabled}
                onClick={handleClickOpen}
              >
                Clear Checkout
              </Button>
              <Dialog open={open} onClose={handleClose}>
                {checkOutLoading ? (
                  <>
                    <div className="flex flex-col items-center justify-center h-44 w-44">
                      <CircularProgress />
                      <p className="text-xs text-red-700">{err}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <DialogTitle>
                      {"Are you sure want to clear this check out?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText >
                        {userInfo.firstName} {userInfo.lastName} has been
                        checked out at <span>{userInfo.checkOut}</span>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                      <Button onClick={handleClearCheckOut} autoFocus>
                        Clear CheckOut
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserAttenLayout;
