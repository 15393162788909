import React, {useState} from 'react';

import { Button } from "@mui/material";
import { Document, Page } from 'react-pdf';

import "react-datepicker/dist/react-datepicker.css";
import {apiService} from "../../../services/apiService";
import axios from "axios";
import PDFViewer from 'pdf-viewer-reactjs'
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const columns = [
    { field: "user", headerName: "AID", width: 150 },
    { field: "checkIn", headerName: "First Name", width: 150 },
    { field: "checkOut", headerName: "Last Name", width: 150 },
    { field: "totalBreakHrs", headerName: "Date", width: 150 },
    { field: "totalHrs", headerName: "check In", width: 150 },

];



function DailyReport() {


    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const[isLoading, setIsLoading] = useState(false)
    const[apiErr, setApiErr] = useState('')
    const[apiRes, setApiRes] = useState()

    const [repData, setRepData] = useState([])


    function formatDate(date) {

        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        var formattedDate = year + '-' + month + '-' + day;

        return formattedDate;
    }






    const handleDailyRp = async () => {
        setIsLoading(true)
        const respData = await apiService.get(`/reports/${startDate}`)
            .then((r) => r.data)

        if(respData.statusCode === 200){
            setApiRes(respData.apiData)

            window.open(respData.apiData)

            setIsLoading(false)

        }
        else {
            setIsLoading(false)
            setApiErr(respData.message)
        }

    }


    const GetCheckInOutRep = async () => {
        const resp = await axios.get(`http://localhost:5022/v1/reports/getcheck-in-out-report/2024-03-19`)
            .then((r) => r.data)

        setRepData(resp.apiData)
    }



    return (
        <>

            {/*<div className="min-h-screen flex justify-center items-center">
                <div className="bg-gray-200 p-6 rounded-lg">
                    {
                        isLoading ?
                            <>
                                <p> Initializing Data ....</p>


                            </> :
                            <>
                                <p className="text-red-700">{apiErr}</p>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) =>
                                        setStartDate(formatDate(date))}

                                />
                                <Button onClick={handleDailyRp}>
                                    Get Report
                                </Button>

                            </>
                    }

                </div>
            </div>*/}
            <Button onClick={GetCheckInOutRep}>Get Data</Button>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Basic date picker" className="h-4"/>

                </LocalizationProvider>


            <div className="h-96">
                <DataGrid
                    getRowId={(row) => row.attenId}
                    columns={columns}
                    rows={repData}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                />
            </div>



        </>


    );
}

export default DailyReport;