import {
    createSlice,
    createAsyncThunk,
  } from "@reduxjs/toolkit";
  import { apiService } from "../services/apiService";
  
  const initialState = {
    isLoading: false,
    Error: null,
    data: [],
  };
  
  export const fetchMasterStatus = createAsyncThunk(
    "masterStatus/fetchMasterStatus",
    async (arg, { rejectWithValue }) => {
      try {
        const resp = await apiService.get("/directs/userstatus-master");
        return resp.data;
      } catch (err) {
        return rejectWithValue({ error: err.message });
      }
    }
  );
  
  export const masterAttnSlice = createSlice({
    name: "masterStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMasterStatus.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchMasterStatus.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.Error = null;
          state.data = payload.apiData;
        })
        .addCase(fetchMasterStatus.rejected, (state, action) => {
          state.Error = action.error.message;
        });
    },
  });
  
  export default masterAttnSlice.reducer;
  