import React from "react";

const UserAttenInfo = (props) => {
  const userInfo = props.userInfo
  return (
    <div>
      <div>
        <h1 className="text-center text-2xl text-gray-700 mt-1 mb-8">
          Attendance Info
        </h1>
        <div>
          <div className="mt-1 text-gray-700 flex justify-between">
            <div>
              <h1 className="text-2xl">
                Name:{" "}
                <span>
                  {userInfo.firstName}
                  {" "}
                  {userInfo.lastName}
                </span>
              </h1>
              <h1>
                UID:{" "}
                <span className="text-sm">
                  {userInfo.userAccount.userId}
                </span>
              </h1>
              <h1>
                AZUREID:{" "}
                <span className="text-sm">
                  {userInfo.userAccount.azureId}
                </span>
              </h1>
              <h1>
                EMP ID: <span className="text-sm">{userInfo.userAccount.empId}</span>
              </h1>
            </div>
            <div></div>
          </div>
          <div className=" text-slate-700 shadow-md h-24 ">
            <div className="p-6 text-center flex justify-between">
            <div className="">
              <h1>Check In</h1>
              <h1>{userInfo.checkIn}</h1>
            </div>
            <div>
              <h1>Check Out</h1>
              <h1>{userInfo.checkOut}</h1>
            </div>
            <div>
              <h1>Brake Hrs</h1>
              <h1>{userInfo.brakeHrs}</h1>
            </div>
            <div>
              <h1>Meeting Hrs</h1>
              <h1>{userInfo.meetingHrs}</h1>
            </div>
            <div>
              <h1>Total hrs</h1>
              <h1>{userInfo.totalHrs}</h1>
            </div>
            <div>
              <h1>Productivity hrs</h1>
              <h1>{userInfo.productivityHrs}</h1>
            </div>
            <div>
              <h1>Lack hrs</h1>
              <h1>{userInfo.lackHrs}</h1>
            </div>

            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAttenInfo;
