import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicLayout from "./layouts/PublicLayout";
import {loginRequest} from "./configs/authConfigs";
import {MsalAuthenticationTemplate, useMsal} from "@azure/msal-react";



function App() {
    const { instance, accounts, inProgress  } = useMsal();


    return (
    <div className="App">

            <Routes>

                    <Route exact path="app/*" element={<PrivateRoutes />} />
                    <Route path="/" element={<PublicLayout />} />
            </Routes>

    </div>
  );
}

export default App;
