import React from "react";
import { CircularProgress } from "@mui/material";

const UserAttenMeetings = ({ attnMeetings }) => {
  return (
    <div>
      <h1 className="text-slate-600 mb-2">Meetings</h1>

      {attnMeetings.isProgressLoading ? (
        <>
          <div className=" h-96 rounded-md p-4 overflow-y-auto bg-slate-50 shadow-md w-96">
            <div className="flex flex-col items-center justify-center h-full ">
              <CircularProgress />
              <p className="text-xs text-red-700">{attnMeetings.Error}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className=" h-96 rounded-md p-4 overflow-y-auto bg-slate-50 shadow-md w-96">
            {attnMeetings.MeetingData.length === 0 ? (
              <>
                <div className="flex flex-col items-center justify-center h-full ">
                  <h1>No Data</h1>
                </div>
              </>
            ) : (
              attnMeetings.MeetingData.map((b) => (
                <div
                  className="rounded-xl bg-gray-100 text-sm  text-gray-900 shadow-sm"
                  key={b.meetId}
                >
                  <div className="p-2 text-xs">
                    <h1>Meeting Start: {b.meetingStart}</h1>
                    <h1>Meeting End: {b.meetingEnd}</h1>
                    <p>Meet For:</p>
                    <div className="h-12  mt-1  rounded-md overflow-y-auto">
                      <p>{b.meetingFor}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserAttenMeetings;
