import React, {useEffect} from "react";
import SignIn from "../components/Auth/SignIn";
import { apiService } from "../services/apiService";
import colorlogo from "../assets/timeKeeper_clr.png";

import {  UnauthenticatedTemplate } from '@azure/msal-react';


const PublicLayout = () => {


  return (
    <div>



      <div className="flex justify-between fixed">
        <div>
          <div className=" items-center flex flex-col mt-48 ">
            <div className="flex flex-col w-1/2 h-auto">
              <img src={colorlogo} />
            </div>
            <div className="flex-grow">
              <SignIn />
            </div>
            <div className=" w-full inset-x-0 bottom-0 left-0 py-5 px-4 items-center ">
              <p className="text-xs flex flex-col text-gray-600 items-center">
                Powered By KOSOFT
              </p>
            </div>
          </div>
        </div>
        <div className="w-screen bg-purple-700 h-screen"></div>
      </div>

    </div>
  );
};

export default PublicLayout;
