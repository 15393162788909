import React from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {Box, Typography} from "@mui/material";

const columns = [
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "empId", headerName: "Emp ID", width: 120 },
    { field: "checkIn", headerName: "Check In", width: 120 },
    { field: "checkOut", headerName: "Check Out", width: 120 },
    { field: "totalBreakHrs", headerName: "Brake Hrs", width: 120 },
    { field: "totalHrs", headerName: "TotalHrs", width: 120 },
    { field: "productivityHrs", headerName: "Productivity Hrs", width: 120 },

];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{
                fileName: `timeKeeper-lessproductivity`,
                delimiter: ';',
                utf8WithBom: true,
            }}/>
            <Box className="text-gray-700" style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant="h6"> LESS PRODUCTIVITY REPORT</Typography>
            </Box>
        </GridToolbarContainer>
    );
}
function LessProductivityReport(props) {
    return (
        <div className="h-80 mt-10">
            {/*<h1 className="text-blue-900 text-xl font-semibold mt-14 mb-4 justify-center items-center flex">*/}
            {/*LESS PRODUCTIVITY REPORT*/}
            {/*</h1>*/}

            <DataGrid
                loading={props.lessProdRpLoading}
                getRowId={(row) => row.attenId}
                columns={columns}
                rows={props.lessProdRp}
                slots={{
                    toolbar: CustomToolbar,
                }}


            />
        </div>
    );
}

export default LessProductivityReport;