import React from 'react';
import {CircularProgress} from "@mui/material";



function ActiveUsers(props) {
    return (

        <>
            <div
                className="w-56 h-36  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="p-4">
                    <h1 className="font-semibold text-blue-900 text-xs justify-center text-center
                        ">Active Users</h1>
                    <h1 className="text-8xl text-blue-900  justify-center text-center">

                        {props.dailyStatsLoading ? <CircularProgress /> : props.dailyStats.activeUser}
                    </h1>

                </div>

            </div>
        </>)
}

function NotCheckInUsers(props) {
    return (

        <>
            <div
                className="w-56 h-36  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="p-4">
                    <h1 className="font-semibold text-blue-900 text-xs justify-center text-center
                        ">NOT CHECK IN</h1>
                    <h1 className="text-8xl text-blue-900  justify-center text-center">
                        {/*{props.dailyStats.notCheckIn.length !== 0 ? props.dailyStats.notCheckIn : <CircularProgress />}*/}
                        {props.dailyStatsLoading ? <CircularProgress /> : props.dailyStats.notCheckIn}
                    </h1>

                </div>

            </div>
        </>)
}

function NotCheckOutUsers(props) {
    return (

        <>
            <div
                className="w-56 h-36  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="p-4">
                    <h1 className="font-semibold text-blue-900 text-xs justify-center text-center
                        ">NOT CHECKOUT</h1>
                    <h1 className="text-8xl text-blue-900  justify-center text-center">
                        {/*{props.dailyStats.notCheckOut.length !== 0 ? props.dailyStats.notCheckOut : <CircularProgress />}*/}
                        {props.dailyStatsLoading ? <CircularProgress /> : props.dailyStats.notCheckOut}
                    </h1>

                </div>

            </div>

        </>)
}

function ProperCheckout(props) {
    return (

        <>
            <div
                className="w-56 h-36  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="p-4">
                    <h1 className="font-semibold text-blue-900 text-xs justify-center text-center
                        ">CHECK IN & OUT</h1>
                    <h1 className="text-8xl text-blue-900  justify-center text-center">
                        {/*{props.dailyStats.properCheckOuts.length !== 0 ? props.dailyStats.properCheckOuts : <CircularProgress />}*/}
                        {props.dailyStatsLoading ? <CircularProgress /> : props.dailyStats.properCheckOuts}
                    </h1>

                </div>

            </div>

        </>)
}


function LessProductive(props) {
    return (

        <>
            <div
                className="w-56 h-36  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="p-4">
                    <h1 className="font-semibold text-blue-900 text-xs justify-center text-center
                        ">LESS PRODUCTIVE</h1>
                    <h1 className="text-8xl text-blue-900  justify-center text-center">
                        {/*{props.dailyStats.lessProductivity.length !== 0 ? props.dailyStats.lessProductivity : <CircularProgress />}*/}
                        {props.dailyStatsLoading ? <CircularProgress /> : props.dailyStats.lessProductivity}
                    </h1>

                </div>

            </div>
        </>)
}

function DailyReportStats(props) {
    return (
        <div>
            <p className="p-2 text-2xl">Daily Report Stats</p>

            <div className=" rounded-2xl bg-gray-200 h-44 ">
                <div className="p-1">
                    <div className="flex p-4 space-x-2">
                        <ActiveUsers dailyStats={props.dailyStats} dailyStatsLoading={props.dailyStatsLoading}/>
                        <NotCheckInUsers dailyStats={props.dailyStats} dailyStatsLoading={props.dailyStatsLoading}/>
                        <NotCheckOutUsers dailyStats={props.dailyStats} dailyStatsLoading={props.dailyStatsLoading}/>
                        <ProperCheckout dailyStats={props.dailyStats} dailyStatsLoading={props.dailyStatsLoading}/>
                        <LessProductive dailyStats={props.dailyStats} dailyStatsLoading={props.dailyStatsLoading}/>


                    </div>
                </div>

            </div>
        </div>
    );
}

export default DailyReportStats;