import React from "react";

const MasterAttenStats = () => {
  return (
    <div>
      <div className="h-32  items-center flex flex-col bg-slate-200">
        <p className="text-sm mt-14 text-gray-400">
          Attendance Master Stats Coming soon
        </p>
      </div>
    </div>
  );
};

export default MasterAttenStats;
