import React, { useEffect, useState } from "react";
import AddUser from "./AddUser";
import UsersList from "./UsersList";
import { apiService } from "../../services/apiService";
import UserMgmtStats from "./UserMgmtStats";

const UserManagement = () => {

  const [userListdata, setUsersList] = useState([])

  useEffect(() =>{
    GetUsers()
  },[])
  const GetUsers = async () => {
    const resp = await apiService.get("/accounts/users")
    if(resp.data.sucess){
      setUsersList(resp.data.apiData)
    }
  }


  return (
    <div>
      <div className="">
        <UserMgmtStats/>
        <div className="flex justify-end">
          <AddUser GetUsers={GetUsers}/>
        </div>
        <div className="w-full h-96 mt-4">
          <UsersList userListdata={userListdata}/>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
