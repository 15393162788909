import React from 'react'

const DashBoard = () => {
  return (
    <div> 
      <div className=" items-center flex flex-col mt-64 ">
        <p className='text-4xl text-gray-500'>Comming Soon</p>
      </div>
    </div>
  )
}

export default DashBoard