import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppUsers } from "../../features/appUsersSlice";
import { useNavigate } from "react-router";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
const UsersList = (props) => {
  const appUsers = useSelector((state) => state.appUsers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const renderIsActive = (params) => {
    const value = Number(params.value);
    return <>{value ? <CheckIcon /> : <CloseIcon />}</>;
  };

  const renderIsEXP = (params) => {
    const value = Number(params.value);
    return <>{value ? <CheckIcon /> : <CloseIcon />}</>;
  };
  // const rows = [];
  const columns = [
    { field: "userId", headerName: "#ID", width: 150 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 150,
      renderCell: renderIsActive,
    },

    { field: "isAttenExecption", headerName: "Is Attendance Exp", width: 150, renderCell: renderIsEXP },
  ];

  const handleRowClick = async (params) => {
    const rowId = params.row.userId;
    console.log("Clicked row ID:", rowId);
    navigate(`/app/users/${rowId}`);
  };

  return (
    <div className="h-full">
      <DataGrid
        getRowId={(row) => row.userId}
        rows={appUsers.data}
        columns={columns}
        loading={appUsers.isLoading}
        error={true}
        errorMessage={appUsers.error}
        errorOverlayStyle={{
          backgroundColor: "red",
          color: "white",
        }}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default UsersList;
