import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import moment from "moment";

const columns = [
  { field: "firstName", headerName: "First Name", width: 150 },
  { field: "lastName", headerName: "Last Name", width: 150 },
  { field: "date", headerName: "Date", width: 150 },
  { field: "checkIn", headerName: "check In", width: 150 },
  { field: "meetingHrs", headerName: "Meeting Hrs", width: 150 },
  { field: "totalHrs", headerName: "Total hrs", width: 150 },
  { field: "productivityHrs", headerName: "Prod Hrs", width: 150 },
  { field: "lackHrs", headerName: "Lack Hrs", width: 150 },
  { field: "rating", headerName: "Rating", width: 150 },
  {
    field: "isProdLessThanEight",
    headerName: "Prod Less than 8hrs",
    width: 150,
  },
  { field: "isCheckIn", headerName: "Is Checked In", width: 150 },
  { field: "isCheckOut", headerName: "Is Checked Out", width: 150 },
  { field: "remarks", headerName: "Remarks", width: 150 },
];

const MyDirectsAttendance = (props) => {
  const [errorMessage, setErrorMessage] = useState("Failed to load");

 



  return (
    <div className="h-96">
      <div className="bg-slate-50 shadow-md h-96">
        <DataGrid
          getRowId={(row) => row.attenId}
          rows={props.directsAtten.data}
          columns={columns}
          loading={props.directsAtten.isLoading}
          error={true}
          errorMessage={errorMessage}
          errorOverlayStyle={{
            backgroundColor: "red",
            color: "white",
          }}

        />
      </div>
    </div>
  );
};

export default MyDirectsAttendance;
