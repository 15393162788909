import React, {useEffect, useState} from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import CheckInOutReport from "./CheckInOutReport";
import LessProductivityReport from "./LessProductivityReport";
import NotCheckOutReport from "./NotCheckOutReport";
import dayjs from 'dayjs';
import DailyReportStats from "./DailyReportStats";
import NotCheckInReport from "./NotCheckInReport";
import {apiService} from "../../../services/apiService";


function DailyReportsLayout() {


    const [rpDate, setRpData] = useState(dayjs)

    const [checkInOutRp, setCheckInOutRp] = useState([])
    const [lessProdRp, setLessProdRp] = useState([])
    const [notCheckOutRp, setNotCheckOutRp] = useState([])

    const [checkInOutLoading, setCheckInOutLoading] = useState(true)
    const [lessProdRpLoading, setLessProdRpLoading] = useState(true)
    const [notCheckOutRpLoading, setNotCheckOutRpLoading] = useState(true)

    const [checkInOutErr, setCheckInOutErr] = useState('')
    const [lessProdRpErr, setLessProdRpErr] = useState('')
    const [notCheckOutRpErr, setNotCheckOutRpErr] = useState('')

    const [notCheckInRp, setNotCheckInRp] = useState([])
    const [notCheckInLoading, setNotCheckInLoading] = useState([])
    const [notCheckInErr, setNotCheckInErr] = useState([])


    const [dailyStats, setDailyStats] = useState()
    const [dailyStatsLoading, setDailyStatsLoading] = useState(true)


    useEffect(() => {
        const currentDate = rpDate.format('YYYY-MM-DD')


        GetAllReport(currentDate)
    }, [rpDate]);


    const GetAllReport = async (date) => {

        try {

            setCheckInOutLoading(true)
            setLessProdRpLoading(true)
            setNotCheckOutRpLoading(true)
            setNotCheckInLoading(true)
            setDailyStatsLoading(true)

            const checkInOutResp = await apiService.get(`/reports/checkinout/${date}`).then((r) => r.data)
            if (checkInOutResp.sucess) {
                if (checkInOutResp.apiData.length > 0) {
                }
                setCheckInOutRp(checkInOutResp.apiData)
                setCheckInOutLoading(false)
            } else {
                setCheckInOutErr(checkInOutResp.message)
                setCheckInOutLoading(false)
            }

            const LessProdResp = await apiService.get(`/reports/lessprod/${date}`).then((r) => r.data)
            if (LessProdResp.sucess) {
                setLessProdRp(LessProdResp.apiData)
                setLessProdRpLoading(false)
            } else {
                setLessProdRpErr(LessProdResp.message)
                setLessProdRpLoading(false)
            }

            const NotCheckOutResp = await apiService.get(`/reports/notCheckout/${date}`).then((r) => r.data)
            if (NotCheckOutResp.sucess) {
                setNotCheckOutRp(NotCheckOutResp.apiData)
                setNotCheckOutRpLoading(false)
            } else {
                setNotCheckOutRpErr(NotCheckOutResp.message)
                setNotCheckOutRpLoading(false)
            }
            const NotCheckInResp = await apiService.get(`/reports/notcheckin/${date}`).then((r) => r.data)
            if (NotCheckInResp.sucess) {
                setNotCheckInLoading(false)
                setNotCheckInRp(NotCheckInResp.apiData)
            } else {
                setDailyStatsLoading(false)
                setNotCheckInErr(NotCheckInResp.message)
            }


            const dailyStatsResp = await apiService.get(`/reports/dailystats/${date}`).then((r) => r.data)
            if (dailyStatsResp.sucess) {
                setDailyStatsLoading(false)
                setDailyStats(dailyStatsResp.apiData)
            } else {
                setDailyStatsLoading(false)
            }


        } catch (e) {
            console.log(e.message)
        }


    }


    return (
        <div className="w-full">
            <div className="mt-2 mb-4  justify-between items-center w-full">
                <h1 className="flex text-slate-800 text-4xl ">Daily Report</h1>
            </div>
            <div className="bg-gray-200 h-32 w-full rounded-md">
                <div className="flex h-16 space-x-12 justify-center items-center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker label="Report Date" className="h-4"
                                    value={rpDate}
                                    onChange={(date) =>
                                        setRpData(date)}
                        />
                    </LocalizationProvider>

                </div>
            </div>
            <div>
                <DailyReportStats dailyStats={dailyStats} dailyStatsLoading={dailyStatsLoading}/>

            </div>

            <div className="">
                <div className="flex mt-10 mb-20 justify-between">
                    <NotCheckInReport notCheckInRp={notCheckInRp} notCheckInLoading={notCheckInLoading}
                                      notCheckInErr={notCheckInErr}/>
                    <NotCheckOutReport notCheckOutRp={notCheckOutRp} notCheckOutRpLoading={notCheckOutRpLoading}
                                       notCheckOutRpErr={notCheckOutRpErr}/>

                </div>
                <div>
                    <CheckInOutReport checkInOutRp={checkInOutRp} checkInOutLoading={checkInOutLoading}
                                      checkInOutErr={checkInOutErr}/>
                    <LessProductivityReport lessProdRp={lessProdRp} lessProdRpLoading={lessProdRpLoading}
                                            lessProdRpErr={lessProdRpErr}/>
                </div>
            </div>


        </div>
    );
}

export default DailyReportsLayout;


