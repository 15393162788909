import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../services/apiService";

const initialState = {
  isLoading: false,
  Error: null,
  data: [],
};

export const fetchGetUser = createAsyncThunk(
  "getUser/fetchGetUser",
  async (userId, { rejectWithValue }) => {
    try {
      const resp = await apiService.get(
        `/accounts/getUser/${userId}`
      );
      return resp.data;
    } catch (err) {
      return rejectWithValue({ error: err.message });
    }
  }
);

export const getUserSlice = createSlice({
    name: "getUser",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchGetUser.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchGetUser.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.Error = null;
          state.data = payload.apiData;
        })
        .addCase(fetchGetUser.rejected, (state, action) => {
          state.Error = action.error.message;
        });
    },

})

export default getUserSlice.reducer;